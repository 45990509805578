import styled from "styled-components/macro";
import ReactSlider from "react-slider";
import { FaRegTimesCircle } from "react-icons/fa";
import arrow_down from "../../assets/icons/arrow-down.svg";

const styles = {
  SpinnerContainer: styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  `,
  SmallButtonsContainer: styled.div`
    display: flex;
    width: 420px;
    gap: 24px;
  `,
  ButtonsInnerContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
  `,
  DockerButtonsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
  TwoColumnContainer: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 85vh;
    gap: 1rem;
  `,
  InfoContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  InputAndErrorContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ContainerRow: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  `,
  ListContainer: styled.div`
    border: 1px solid ${({ theme }) => theme.colors.neutral.grey300};
    padding: 3px;
    max-height: 150px;
    overflow-y: auto;
  `,
  Row: styled.div`
    font-size: ${({ theme }) => theme.fontSizes.field};
    padding-top: 3px;
    padding-bottom: 3px;
  `,
  Button: styled.div`
    font-size: 14px;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
    text-align: center;
  `,
  LabelButton: styled.label`
    font-size: 14px;
    cursor: pointer;
  `,
  Label: styled.label`
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin-bottom: 3px;
  `,
  CheckboxAndIconContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  `,
  Input: styled.input`
    width: ${(props) => (props.narrow ? "auto" : "420px")};
    height: 47px;
    background: ${({ theme }) => theme.colors.neutral.white};
    font-size: ${({ theme }) => theme.fontSizes.field};

    padding: 11px 16px;
    border-radius: 12px;
    border: none;
    outline: none;

    &:disabled {
      opacity: 0.7;
    }
  `,
  TextArea: styled.textarea`
    background: ${({ theme }) => theme.colors.neutral.white};
    border: 1px solid ${({ theme }) => theme.colors.neutral.grey300};
    box-shadow: 0px 1px 2px ${({ theme }) => theme.colors.shadows.light};
    border-radius: 8px;
    height: 60px;
    width: 100%;
    background: transparent;
    font-size: 14px;
    padding: 0 0.5rem;
    outline: none;
  `,
  ComboSelect: styled.div`
    width: 100%;
  `,
  Select: styled.select`
    border: none;
    outline: none;
    appearance: none;
    display: flex;
    padding: 11px 16px;
    height: 47px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 12px;
    cursor: pointer;
    background-image: url(${arrow_down});
    background-repeat: no-repeat;
    background-position: right 10px center;
  `,
  Optgroup: styled.optgroup``,
  Option: styled.option``,
  SubmitInput: styled.input`
    background: ${({ theme }) => theme.colors.warning.orange700};
    border: none;
    border-radius: 100px;
    width: 132px;
    height: 40px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.neutral.white};
    font-size: 14px;
    cursor: pointer;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    ${(props) =>
      props.disabled &&
      `
        background: ${({ theme }) => theme.colors.neutral.grey300};
        cursor: not-allowed;
      `}
  `,
  SubmitError: styled.span`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.error.red500};
    padding-left: 5px;
    padding-top: 5px;
  `,
  SplitContainer: styled.div`
    display: flex;
    justify-content: center;
  `,
  Text: styled.span`
    font-size: 14px;
  `,
  SelectVenueContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,

  VenueCheckboxContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
  `,
  RemoveVenueIcon: styled(FaRegTimesCircle)`
    color: ${({ theme }) => theme.colors.warning.orange700};
    width: 25px;
    height: 25px;
    cursor: pointer;
  `,
  AddedVenueContainer: styled.div`
    display: flex;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.neutral.grey300};
    box-shadow: 0px 1px 2px ${({ theme }) => theme.colors.shadows.light};
    border-radius: 8px;
    height: 25px;
    width: 100%;
    background: ${({ theme }) => theme.colors.neutral.grey300};
    font-size: 14px;
    padding: 0 0.5rem;
  `,
  AddNewVenueContainer: styled.div`
    display: flex;
  `,
  ImageCard: styled.img`
    width: 100%;
    resize-mode: contain;
  `,
  AddNewVenueText: styled.span`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.warning.orange700};
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  `,
  LoadingMessage: styled.p`
    font-size: 1rem;

    animation: bounce 2s ease-in-out infinite;

    @keyframes bounce {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
        text-shadow: 0px 10px ${({ theme }) => theme.colors.shadows.light};
      }
      100% {
        transform: translateY(0);
      }
    }
  `,
  InfoMessage: styled.p`
    font-size: 0.85rem;
    color: ${({ theme }) => theme.colors.error.red300};
  `,
  Slider: styled(ReactSlider)`
    width: 100%;
    height: 7px;
  `,
  SliderThumb: styled.div`
    height: 24px;
    width: 24px;
    background-color: white;
    border: 5px solid ${({ theme }) => theme.buttons.primary};
    border-radius: 50%;
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    top: 50%;
    transform: translateY(-50%);
  `,
  SliderTrack: styled.div`
    top: 0;
    bottom: 0;
    background: ${(props) => (props.index === 0 ? "#394DFC" : "#F0F2F7")};
    border-radius: 999px;
    height: 7px;
  `,

  SliderValue: styled.span`
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.medium};
    margin-left: 10px;
  `,
  SliderContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  InnerFormContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
  `,
  RadioContainer: styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  RadioInput: styled.input`
    cursor: pointer;

    appearance: none;
    width: 15px;
    height: 15px;
    background-color: white;
    border: 1.5px solid #e1e3e8;
    border-radius: 50%;
    cursor: pointer;
    position: relative;

    &:checked::before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.buttons.primary};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.25);
    }
  `,
  RadioLabel: styled.label`
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
  `,
  RadioInputLabelContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  CameraFormContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  CalendarToggleContainer: styled.div`
    display: flex;
    width: 420px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  `,
  ButtonToggleContainer: styled.div`
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    justify-content: center;

    border-radius: 32px;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  ToggleButton: styled.button`
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    height: 41px;

    border-radius: 40px;
    border: none;
    background-color: ${({ selected, theme }) =>
      selected ? theme.colors.primary.blue200 : theme.colors.neutral.white};
    color: ${({ selected, theme }) =>
      selected ? theme.text.primary : theme.colors.neutral.grey400};
    cursor: pointer;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    font-size: ${({ theme }) => theme.fontSizes.button};
  `,
  CaptureOptionsContainer: styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  `,
  CaptureOption: styled.div`
    display: flex;
    padding: 8px 15.845px 9px 16px;
    justify-content: center;
    align-items: center;
    gap: ${({ hasDate }) => (hasDate ? "20px" : "65px")}; /* Dynamic gap */
    flex: 1 0 0;
    width: 204px;
    height: 47px;

    border-radius: 12px;
    opacity: 0.6;
    background: ${({ theme }) => theme.colors.neutral.white};
  `,
  CaptureLabelIconContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
  `,
  CaptureLabel: styled.div`
    font-size: 10px;
    width: 90px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    text-transform: uppercase;
    opacity: 0.6;
  `,
  CaptureValue: styled.div`
    font-size: ${({ theme }) => theme.fontSizes.field};
    color: ${({ theme }) => theme.text.primary};
    font-weight: ${({ theme }) => theme.fonts.weights.medium};
    text-wrap: nowrap;
  `,
  DateTimeInput: styled.input`
    font-size: ${({ theme }) => theme.fontSizes.field};
    max-width: 204px;
    font-size: 10px;
    border: none;
    outline: none;
  `,
  DatePickerContainer: styled.div`
    align-self: center;
  `,
  CheckboxLabelContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 11px;
    flex: 1 0 0;
  `,
  Divider: styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    width: 388px;
    height: 1px;
    align-self: center;
  `,
  CheckboxContainerRow: styled.div`
    display: flex;
    align-items: flex-start;
    gap: 11px;
  `,
  CameraDetailsContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  CameraTitle: styled.span`
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fonts.weights.medium};
  `,
  CameraSubtitle: styled.span`
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
  `,
  CameraDate: styled.span`
    font-size: 12px;
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    margin-top: 4px;
    opacity: 0.6;
  `,
  ToggleIcon: styled.div`
    cursor: pointer;
    margin-left: 10px;
  `,
  DetailsContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 20px;
    margin-top: 16px;
  `,
  MachineHeaderContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    width: 388px;
  `,
  MachineHeaderInnerContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 11px;
  `,
};

export default styles;
