import React, { useState, useContext, useEffect } from "react";
import { useMutation } from "@apollo/client/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Styles from "../styles";
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import ObjectSelector from "../../../components/ObjectSelector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CREATE_COLLECTION_REQUEST,
  DELETE_COLLECTION_REQUEST,
  LIST_COLLECTION_REQUESTS,
  UPDATE_COLLECTION_REQUEST,
} from "../../../graphql/graph";
import { DashboardContext } from "../../../context/DashboardContext";
import { useToastModal } from "../../../context/ToastModalContext";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import Icon from "components/Shared/Icon/Icon";
import { formatDatePickerDate } from "utils/formatDate";
import { CAPTURE_NOW } from "constants/constants.ts";

const inputFormSchema = yup.object().shape({
  machineId: yup.string().required("Camera is a required field."),
});

const StartCaptureRequest = ({
  venuesList,
  companyId,
  showModal,
  setShowModal,
  requestToUpdate,
  onCloseModal,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [captureTiming, setCaptureTiming] = useState(CAPTURE_NOW);
  const isCaptureNow = captureTiming === CAPTURE_NOW;

  const { addToast, addModal } = useToastModal();
  const { objectDetectionItems } = useContext(DashboardContext);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isStartPickerOpen, setStartPickerOpen] = useState(false);
  const [isEndPickerOpen, setEndPickerOpen] = useState(false);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(inputFormSchema),
    defaultValues: {
      machineId: null,
      crStartNow: 1,
      crStartTimestamp: null,
      crEndTimestamp: null,
      crMaxFrames: null,
      crInterval: null,
      crManualReview: 1,
      crDetectionItems: [],
    },
  });

  const [
    addCollectionMutation,
    { error: addCollectionError, loading: addLoading, data: addCollectionData },
  ] = useMutation(CREATE_COLLECTION_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COLLECTION_REQUESTS,
        context: { clientName: "graph" },
        variables: {
          companyId,
        },
      },
    ],
  });

  const [
    updateCollectionMutation,
    { error: updateCollectionError, loading: updateLoading, data: updateCollectionData },
  ] = useMutation(UPDATE_COLLECTION_REQUEST, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_COLLECTION_REQUESTS,
        context: { clientName: "graph" },
        variables: {
          companyId,
        },
      },
    ],
  });

  const [deleteCollectionMutation, { data: deleteCollectionData, error: deleteCollectionError }] =
    useMutation(DELETE_COLLECTION_REQUEST, {
      context: { clientName: "graph" },
      refetchQueries: [
        {
          query: LIST_COLLECTION_REQUESTS,
          context: { clientName: "graph" },
          variables: {
            companyId,
          },
        },
      ],
    });

  const loading = addLoading || updateLoading;
  const watchAll = watch();

  useEffect(() => {
    if (deleteCollectionData) {
      addToast("Collection deleted successfully!", "success");
      handleCloseModal();
    }
    if (deleteCollectionError) {
      addToast(
        `Something went wrong! Collection did not delete. ${deleteCollectionError.message}`,
        "error",
      );
    }
    if (addCollectionData) {
      addToast("Collection created successfully!", "success");
      handleCloseModal();
    }
    if (addCollectionError) {
      addToast(
        `Something went wrong! Collection did not create. ${addCollectionError.message}`,
        "error",
      );
    }
  }, [deleteCollectionData, deleteCollectionError, addCollectionData, addCollectionError]);

  useEffect(() => {
    if (updateCollectionData) {
      addToast("Collection updated successfully!", "success");
    }
    if (updateCollectionError) {
      addToast(
        `Something went wrong! Collection did not update. ${updateCollectionError.message}`,
        "error",
      );
    }
  }, [updateCollectionData, updateCollectionError]);

  useEffect(() => {
    if (requestToUpdate) {
      reset(requestToUpdate);
      if (!requestToUpdate.crStartNow) {
        setStartDate(new Date(requestToUpdate.crStartTimestamp * 1000));
        setValue(
          "crStartTimestamp",
          new Date(requestToUpdate.crStartTimestamp * 1000).toISOString().slice(0, 16),
        );
      }
      if (requestToUpdate.crEndTimestamp !== 0) {
        setEndDate(new Date(requestToUpdate.crEndTimestamp * 1000));
        setValue(
          "crEndTimestamp",
          new Date(requestToUpdate.crEndTimestamp * 1000).toISOString().slice(0, 16),
        );
      }
    }
  }, [reset, requestToUpdate]);

  const handleObjectChange = (objects) => setValue("crDetectionItems", objects || []);

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setValue(name, parseInt(value, 10));
  };

  const handleNextStep = () => setCurrentStep((prevStep) => prevStep + 1);
  const handlePreviousStep = () => setCurrentStep((prevStep) => prevStep - 1);

  const handleCloseModal = () => {
    reset();
    setShowModal((prev) => !prev);
    onCloseModal();
  };

  const handleClickDeleteBtn = async () => {
    addModal("Delete Collection Request?", false, () =>
      deleteCollectionMutation({ variables: { id: requestToUpdate.id } }),
    );
  };

  useEffect(() => {
    if (requestToUpdate) {
      const parsedDetectionItems = Array.isArray(requestToUpdate.crDetectionItems)
        ? requestToUpdate.crDetectionItems
        : JSON.parse(requestToUpdate.crDetectionItems || "[]");
      setValue("crDetectionItems", parsedDetectionItems);
    }
  }, [requestToUpdate]);

  const submitForm = async (form) => {
    if (loading) return;

    const variables = {
      companyId,
      machineId: form.machineId,
      crMaxFrames: parseInt(form.crMaxFrames, 10),
      crManualReview: parseInt(form.crManualReview, 10),
      crInterval: parseInt(form.crInterval, 10),
      crDetectionItems: JSON.stringify(form.crDetectionItems || []),
    };

    if (isCaptureNow) {
      variables.crStartNow = 1;
      variables.crStartTimestamp = 0;
      variables.crEndTimestamp = 0;
    } else {
      variables.crStartNow = 0;
      variables.crStartTimestamp = startDate ? Math.floor(startDate.getTime() / 1000) : 0;
      variables.crEndTimestamp = endDate ? Math.floor(endDate.getTime() / 1000) : 0;
    }

    if (requestToUpdate) {
      updateCollectionMutation({ variables: { id: requestToUpdate.id, ...variables } });
    } else {
      addCollectionMutation({ variables });
      handleCloseModal();
    }
  };

  usePreventEnterAction(submitForm);

  const objectSuggestions = objectDetectionItems.map((item) => ({
    value: item.objectDetectionIndex,
    label: item.objectDetectionLabel,
  }));

  const modalTitle = requestToUpdate ? "Update Capture" : "Start Capture";
  const stepTitles = [{ subtitle: "STEP 1 OF 2" }, { subtitle: "STEP 2 OF 2" }];

  const renderStep1 = () => (
    <>
      <Styles.InputAndErrorContainer>
        <Styles.Select {...register("machineId")} placeholder="Select Camera">
          <option value="">Select Camera</option>
          {venuesList.map((venue) => (
            <optgroup label={venue.venueName} key={venue.id}>
              {venue.Areas.map((area) =>
                area.Machines.map((machine) => (
                  <option key={machine.id} value={machine.id}>
                    {area.areaName} - {machine.machineName}
                  </option>
                )),
              )}
            </optgroup>
          ))}
        </Styles.Select>
        <Styles.SubmitError>{errors.machineId?.message}</Styles.SubmitError>
      </Styles.InputAndErrorContainer>

      <Styles.InputAndErrorContainer>
        <ObjectSelector
          values={watchAll.crDetectionItems}
          onChange={handleObjectChange}
          suggestions={objectSuggestions}
        />
      </Styles.InputAndErrorContainer>

      <Styles.InputAndErrorContainer>
        <Styles.Input type="text" placeholder="Max Frame to Capture" {...register("crMaxFrames")} />
      </Styles.InputAndErrorContainer>

      <Styles.InputAndErrorContainer>
        <Styles.Input
          type="text"
          placeholder="How many seconds per frame?"
          {...register("crInterval")}
        />
      </Styles.InputAndErrorContainer>

      <Styles.InputAndErrorContainer>
        <Styles.RadioContainer>
          <Styles.Label>Data Annotation</Styles.Label>
          <Styles.RadioInputLabelContainer>
            <div>
              <Styles.RadioInput
                type="radio"
                name="crManualReview"
                value="1"
                onChange={handleRadioChange}
                checked={watchAll.crManualReview === 1}
              />
            </div>
            <Styles.RadioLabel>Review before annotation</Styles.RadioLabel>
          </Styles.RadioInputLabelContainer>

          <Styles.RadioInputLabelContainer>
            <div>
              <Styles.RadioInput
                type="radio"
                name="crManualReview"
                value="0"
                onChange={handleRadioChange}
                checked={watchAll.crManualReview === 0}
              />
            </div>
            <Styles.RadioLabel>Send without review</Styles.RadioLabel>
          </Styles.RadioInputLabelContainer>
        </Styles.RadioContainer>
      </Styles.InputAndErrorContainer>
    </>
  );

  const renderStep2 = () => (
    <Styles.CalendarToggleContainer>
      <Styles.ButtonToggleContainer>
        <Styles.ToggleButton
          selected={isCaptureNow}
          onClick={() => setCaptureTiming(CAPTURE_NOW)}
          type="button"
        >
          Now
        </Styles.ToggleButton>

        <Styles.ToggleButton
          selected={!isCaptureNow}
          onClick={() => setCaptureTiming("later")}
          type="button"
        >
          Later
        </Styles.ToggleButton>
      </Styles.ButtonToggleContainer>

      {isCaptureNow ? renderNowCaptureOptions() : renderLaterCaptureOptions()}
    </Styles.CalendarToggleContainer>
  );

  const renderNowCaptureOptions = () => (
    <>
      <Styles.CaptureOptionsContainer>
        <Styles.CaptureOption>
          <Styles.CaptureLabelIconContainer>
            <Styles.CaptureLabel>Start Capture?</Styles.CaptureLabel>
            <Styles.CaptureValue>Immediately</Styles.CaptureValue>
          </Styles.CaptureLabelIconContainer>
          <Icon name="calendar" />
        </Styles.CaptureOption>

        <Styles.CaptureOption hasDate={!!endDate}>
          <Styles.CaptureLabelIconContainer>
            <Styles.CaptureLabel>End Capture?</Styles.CaptureLabel>

            {endDate && <Styles.CaptureValue>{formatDatePickerDate(endDate)}</Styles.CaptureValue>}
          </Styles.CaptureLabelIconContainer>

          <div onClick={() => setEndPickerOpen(true)}>
            <Icon className="action-icon" name="calendar" />
          </div>
        </Styles.CaptureOption>
      </Styles.CaptureOptionsContainer>

      <Styles.DatePickerContainer>
        {isEndPickerOpen && (
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              setEndPickerOpen(false);
            }}
            showTimeSelect
            timeFormat="hh:mm aa"
            timeIntervals={30}
            dateFormat="yyyy/MM/dd hh:mm aa"
            timeCaption="Time"
            inline
          />
        )}
      </Styles.DatePickerContainer>
    </>
  );

  const renderLaterCaptureOptions = () => (
    <>
      <Styles.CaptureOptionsContainer>
        <Styles.CaptureOption hasDate={!!startDate}>
          <Styles.CaptureLabelIconContainer>
            <Styles.CaptureLabel>Start Capture?</Styles.CaptureLabel>

            {startDate && (
              <Styles.CaptureValue>{formatDatePickerDate(startDate)}</Styles.CaptureValue>
            )}
          </Styles.CaptureLabelIconContainer>

          <div
            onClick={() => {
              setStartPickerOpen(!isStartPickerOpen);
              setEndPickerOpen(false);
            }}
          >
            <Icon className="action-icon" name="calendar" />
          </div>
        </Styles.CaptureOption>

        <Styles.CaptureOption hasDate={!!endDate}>
          <Styles.CaptureLabelIconContainer>
            <Styles.CaptureLabel>End Capture?</Styles.CaptureLabel>

            {endDate && <Styles.CaptureValue>{formatDatePickerDate(endDate)}</Styles.CaptureValue>}
          </Styles.CaptureLabelIconContainer>

          <div
            onClick={() => {
              setEndPickerOpen(!isEndPickerOpen);
              setStartPickerOpen(false);
            }}
          >
            <Icon className="action-icon" name="calendar" />
          </div>
        </Styles.CaptureOption>
      </Styles.CaptureOptionsContainer>

      <Styles.DatePickerContainer>
        {isStartPickerOpen && (
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setStartDate(date);
              setStartPickerOpen(false);
            }}
            showTimeSelect
            timeFormat="hh:mm aa"
            timeIntervals={30}
            dateFormat="yyyy/MM/dd hh:mm aa"
            timeCaption="Time"
            inline
          />
        )}
      </Styles.DatePickerContainer>

      <Styles.DatePickerContainer>
        {isEndPickerOpen && (
          <DatePicker
            selected={endDate}
            onChange={(date) => {
              setEndDate(date);
              setEndPickerOpen(false);
            }}
            showTimeSelect
            timeFormat="hh:mm aa"
            timeIntervals={30}
            dateFormat="yyyy/MM/dd hh:mm aa"
            timeCaption="Time"
            inline
          />
        )}
      </Styles.DatePickerContainer>
    </>
  );

  return (
    <ModalWithChildren
      showModal={showModal}
      setShowModal={handleCloseModal}
      title={modalTitle}
      subtitle={stepTitles[currentStep - 1].subtitle}
    >
      <Styles.Form wide onSubmit={handleSubmit(submitForm)}>
        <Styles.CameraFormContainer>
          {currentStep === 1 ? renderStep1() : renderStep2()}
        </Styles.CameraFormContainer>

        <Styles.ButtonsContainer>
          {currentStep === 1 ? (
            <PrimaryBtn
              type="button"
              id="next"
              label="Next"
              width="420px"
              height="40px"
              handleClick={handleNextStep}
            />
          ) : (
            <Styles.SmallButtonsContainer>
              <SecondaryBtn
                width="200px"
                height="40px"
                label="Previous"
                onClick={handlePreviousStep}
              />
              <PrimaryBtn width="200px" height="40px" type="submit" label="Submit" />
            </Styles.SmallButtonsContainer>
          )}
        </Styles.ButtonsContainer>
      </Styles.Form>

      {requestToUpdate && (
        <Styles.Button onClick={handleClickDeleteBtn}>[Cancel Capture Request]</Styles.Button>
      )}
    </ModalWithChildren>
  );
};

export default StartCaptureRequest;
