import React, { useState, useCallback, useEffect } from "react";
import { ReactTags } from "react-tag-autocomplete";
import styled from "styled-components";

const StyledReactTags = styled.div`
  .react-tags {
    border: none;
    outline: none;
    border-radius: 12px;
    width: 420px;
  }
`;

const ObjectSelector = ({ disabled, values, suggestions, onChange }) => {
  const [selected, setSelected] = useState([]);
  const [placeholder, setPlaceholder] = useState("Object detection list");

  useEffect(() => {
    if (values && values.length > 0) {
      shouldUpdateSelected(values);
    }
  }, [values]);

  const shouldUpdateSelected = (parentValues) => {
    if (JSON.stringify(parentValues) !== JSON.stringify(selected)) {
      setSelected(parentValues);
      setPlaceholder(parentValues.length > 0 ? "" : "Object detection list"); // Adjust placeholder
    }
  };

  const onAdd = useCallback(
    (newTag) => {
      const updatedSelected = [...selected, newTag];
      setSelected(updatedSelected);
      setPlaceholder(updatedSelected.length > 0 ? "" : "Object detection list"); // Clear placeholder if tags exist
    },
    [selected],
  );

  const onDelete = useCallback(
    (tagIndex) => {
      const updatedSelected = selected.filter((_, i) => i !== tagIndex);
      setSelected(updatedSelected);
      setPlaceholder(updatedSelected.length > 0 ? "" : "Object detection list"); // Update placeholder based on tags
    },
    [selected],
  );

  const onBlur = useCallback(() => {
    onChange(selected);
  }, [selected, onChange]);

  return (
    <StyledReactTags>
      <ReactTags
        isDisabled={disabled}
        selected={selected}
        suggestions={suggestions}
        onAdd={onAdd}
        onDelete={onDelete}
        onBlur={onBlur}
        noOptionsText="No matching objects"
        placeholderText={placeholder} // Dynamic placeholder
        classNames={{
          root: "react-tags",
          rootIsActive: "is-active",
          rootIsDisabled: "is-disabled",
          rootIsInvalid: "is-invalid",
          label: "react-tags__label",
          tagList: "react-tags__list",
          tagListItem: "react-tags__list-item",
          tag: "react-tags__tag",
          tagName: "react-tags__tag-name",
          comboBox: "react-tags__combobox",
          input: "react-tags__combobox-input",
          listBox: "react-tags__listbox",
          option: "react-tags__listbox-option",
          optionIsActive: "is-active",
          highlight: "react-tags__listbox-option-highlight",
        }}
      />
    </StyledReactTags>
  );
};

export default ObjectSelector;
