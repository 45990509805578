import React from "react";
import Select from "react-select";
import { theme } from "styles/theme";

const CustomSelect = ({
  options,
  placeholder = "Select an option",
  onChange,
  isSearchable = true,
  isDisabled = false,
  value,
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      borderRadius: "12px",
      height: "47px",
      fontSize: theme.fontSizes.field,
    }),
    menu: (provided) => ({
      ...provided,
      border: "none",
      borderRadius: "12px",
      background: theme.colors.neutral.white,
      boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.21)",
      padding: "4px",
      marginTop: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? theme.colors.neutral.grey200 : theme.colors.neutral.white,
      color: "black",
      cursor: "pointer",
      fontWeight: state.isSelected ? "bold" : "normal",
      ":active": {
        backgroundColor: theme.colors.neutral.white,
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "8px",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  return (
    <Select
      value={options.find((option) => option.value === value) || ""}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      styles={customStyles}
    />
  );
};

export default CustomSelect;
